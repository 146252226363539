import React, { Component } from "react";

import Form from "@rjsf/material-ui";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ReactJson from "react-json-view";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

const uiSchema = {
    bannerTypes: {
        "ui:widget": "checkboxes"
    },
    banner: {
        default: {
            href: {
                "ui:placeholder":
                    "/video/1-0-<test-identifier>?utm_source=Website&utm_medium=Webbanner&utm_campaign=<WebCampaign>&utm_content=<utm-identifier>"
            }
        },
        free: {
            href: {
                "ui:placeholder":
                    "/video/1-0-<test-identifier>?utm_source=Website&utm_medium=Webbanner&utm_campaign=<WebCampaign>&utm_content=<utm-identifier>"
            }
        },
        trial: {
            href: {
                "ui:placeholder":
                    "/video/1-0-<test-identifier>?utm_source=Website&utm_medium=Webbanner&utm_campaign=<WebCampaign>&utm_content=<utm-identifier>"
            }
        },
        subscription: {
            href: {
                "ui:placeholder":
                    "/video/1-0-<test-identifier>?utm_source=Website&utm_medium=Webbanner&utm_campaign=<WebCampaign>&utm_content=<utm-identifier>"
            }
        }
    }
};

const schemaCollectionTile = {
    tile: {
        type: "string",
        title: "Tile",
        default: "portrait",
        enum: ["portrait", "landscape"]
    }
};

const schemaCollectionType = {
    type: {
        type: "string",
        title: "Type",
        default: "video",
        enum: [
            "video",
            "continueWatching",
            "incentive",
            "jumbotron",
            "favorites"
        ]
    }
};

const schemaCollectionIncentive = {
    bannerTypes: {
        type: "array",
        title: "Banners",
        minItems: 1,
        default: "default",
        items: {
            type: "string",
            enum: ["default", "free", "trial", "subscription"]
        },
        uniqueItems: true
    },
    banner: {
        type: "object",
        properties: {}
    }
};

const schemaCollectionBanner = {
    type: "object",
    required: ["href"],
    properties: {
        href: {
            type: "string",
            title: "Href"
        }
    }
};

const schemaCollection = {
    type: "object",
    required: ["tile", "type"],
    properties: {
        ...schemaCollectionTile,
        ...schemaCollectionType
    }
};

const tagSchema = code => {
    return {
        title: `${code.toUpperCase()} - Tags`,
        type: "object",
        properties: {
            tags: {
                type: "array",
                title: null,
                items: {
                    title: null,
                    type: "object",
                    required: ["text", "color"],
                    properties: {
                        text: {
                            type: "string",
                            title: "Text",
                            default: null
                        },
                        backgroundColor: {
                            type: "string",
                            title: "Background Color",
                            default: null
                        },
                        textColor: {
                            type: "string",
                            title: "Text color",
                            default: null
                        },
                        color: {
                            type: "string",
                            title: "Color",
                            default: "default",
                            enum: ["default", "primary", "secondary"]
                        }
                    }
                }
            }
        }
    };
};

const langCodes = ["nl", "en", "nb", "sv"];

const fixLangCodesKey = object => {
    const newObject = { ...object };
    const tags = {};

    newObject.indexable = newObject.indexable === true;

    Object.keys(newObject.tags).forEach(
        key => (tags[langCodes[key]] = newObject.tags[key].tags || [])
    );

    newObject.tags = tags;

    return newObject;
};

const schemaMovie = {
    type: "object",
    required: ["tags", ""],
    properties: {
        indexable: {
            type: "boolean",
            title: "Indexable",
            default: true
        },
        tags: {
            type: "object",
            title: "Tags",
            properties: langCodes.map(code => tagSchema(code))
        }
    }
};

class App extends Component {
    state = {
        collectionSchema: { ...schemaCollection },
        collectionJsonValue: null,
        movieSchema: { ...schemaMovie },
        movieJsonValue: null
    };

    onChange = (type, value) => {
        this.setJsonValue(type, value);
    };

    onChangeCollection = value => {
        const { collectionSchema } = this.state;
        const newSchema = { ...collectionSchema };
        const newValue = { ...value };

        // if type is incentive, delete tile from and add banner fields to the schema
        if (newValue.type === "incentive") {
            if (newValue.tile) delete newValue.tile;
            newValue.bannerTypes = newValue.bannerTypes || [
                schemaCollectionIncentive.bannerTypes.default
            ];

            newValue.banner = newValue.banner || {};
            Object.keys(newValue.banner).forEach(key => {
                if (!newValue.bannerTypes.includes(key))
                    delete newValue.banner[key];
            });

            const bannerSchema = {};
            newValue.bannerTypes.forEach(
                bannerType =>
                    (bannerSchema[bannerType] = { ...schemaCollectionBanner })
            );

            newSchema.required = ["type", "banner"];
            newSchema.properties = {
                ...schemaCollectionType,
                ...schemaCollectionIncentive,
                banner: {
                    type: "object",
                    properties: {
                        ...bannerSchema
                    }
                }
            };
        }
        // if type isn't incentive, delete banner fields from and add tile to the schema & values
        else if (newValue.type !== "incentive") {
            if (newValue.banner) delete newValue.banner;
            if (newValue.bannerTypes) delete newValue.bannerTypes;

            newSchema.required = ["type", "tile"];
            newSchema.properties = {
                ...schemaCollectionTile,
                ...schemaCollectionType
            };
        }

        this.setState({
            collectionSchema: newSchema,
            collectionJsonValue: newValue
        });
    };

    setJsonValue = (type, value) =>
        this.setState({ [`${type}JsonValue`]: value });

    render() {
        const {
            collectionJsonValue,
            movieJsonValue,
            collectionSchema,
            movieSchema
        } = this.state;

        const { bannerTypes, ...clipBoardJsonValue } =
            collectionJsonValue || {};

        return (
            <div className="App container" style={{ marginTop: "5vh" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px"
                    }}
                >
                    <img
                        src="https://dutchchannels.com/wp-content/uploads/2020/07/DutchChannels.png"
                        alt="logo"
                    />
                </div>
                <ToastContainer />
                <div className="row">
                    <div className="col-sm-6">
                        <Card>
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                >
                                    Collectie config
                                </Typography>
                                <Form
                                    schema={collectionSchema}
                                    uiSchema={uiSchema}
                                    formData={collectionJsonValue}
                                    onChange={({ formData }) =>
                                        this.onChangeCollection(formData)
                                    }
                                    liveValidate
                                >
                                    <div />
                                </Form>

                                {collectionJsonValue ? (
                                    <div>
                                        <hr />
                                        <ReactJson
                                            src={clipBoardJsonValue}
                                            name={false}
                                            displayObjectSize={false}
                                            displayDataTypes={false}
                                            enableClipboard={false}
                                            collapsed={true}
                                        />
                                        <CopyToClipboard
                                            text={JSON.stringify(
                                                clipBoardJsonValue,
                                                null,
                                                4
                                            )}
                                            onCopy={() =>
                                                toast.success(
                                                    "Copied successfully",
                                                    {
                                                        position:
                                                            toast.POSITION.RIGHT
                                                    }
                                                )
                                            }
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    width: "100%",
                                                    marginTop: 10
                                                }}
                                            >
                                                Click here to copy
                                            </Button>
                                        </CopyToClipboard>
                                    </div>
                                ) : null}
                            </CardContent>
                        </Card>
                    </div>
                    <div className="col-sm-6">
                        <Card>
                            <CardContent>
                                <div className="alert">
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="h2"
                                    >
                                        It is possible to change this in AllIn.
                                    </Typography>
                                </div>
                                <Form
                                    schema={movieSchema}
                                    formData={movieJsonValue}
                                    onChange={({ formData }) =>
                                        this.onChange("movie", formData)
                                    }
                                    liveValidate
                                >
                                    <div />
                                </Form>

                                {movieJsonValue ? (
                                    <div>
                                        <hr />
                                        <ReactJson
                                            src={fixLangCodesKey(
                                                movieJsonValue
                                            )}
                                            name={false}
                                            displayObjectSize={false}
                                            displayDataTypes={false}
                                            enableClipboard={false}
                                            collapsed={true}
                                        />
                                        <CopyToClipboard
                                            text={JSON.stringify(
                                                fixLangCodesKey(movieJsonValue),
                                                null,
                                                4
                                            )}
                                            onCopy={() =>
                                                toast.success(
                                                    "Copied successfully",
                                                    {
                                                        position:
                                                            toast.POSITION.RIGHT
                                                    }
                                                )
                                            }
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    width: "100%",
                                                    marginTop: 10
                                                }}
                                            >
                                                Click here to copy
                                            </Button>
                                        </CopyToClipboard>
                                    </div>
                                ) : null}
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
